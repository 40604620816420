import React from "react";
import styles from "./Footer.module.scss";
import logo from "../../assets/images/footerLogo.svg";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.upperFooter}>
        <img src={logo} alt="" />
        <div className={styles.linksWrapper}>
          <div className={styles.links}>
            <Link to={"/dresses"}>Dresses</Link>
            <Link to={"/makeup"}>Makeup</Link>
          </div>
          <div className={styles.divider}> </div>
          <div className={styles.socials}>
            <Link target="_blank" to={"https://www.facebook.com/thureclothes"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M16.5 9.00005C16.5 4.85791 13.1421 1.50005 9 1.50005C4.85786 1.50005 1.5 4.85791 1.5 9.00005C1.5 12.7435 4.24264 15.8463 7.82812 16.4089V11.168H5.92383V9.00005H7.82812V7.34771C7.82812 5.46802 8.94782 4.42974 10.661 4.42974C11.4815 4.42974 12.3398 4.57622 12.3398 4.57622V6.42192H11.3941C10.4624 6.42192 10.1719 7.00005 10.1719 7.59317V9.00005H12.252L11.9194 11.168H10.1719V16.4089C13.7574 15.8463 16.5 12.7435 16.5 9.00005Z"
                  fill="white"
                />
              </svg>
            </Link>
            <Link
              target="_blank"
              to={"https://www.instagram.com/adelinavinarci_dresses/?hl=en"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 1.5C6.963 1.5 6.708 1.5085 5.908 1.545C5.1095 1.5815 4.564 1.7085 4.087 1.894C3.587 2.082 3.1335 2.377 2.7585 2.759C2.37711 3.13357 2.08193 3.58675 1.8935 4.087C1.709 4.564 1.5815 5.11 1.545 5.9085C1.509 6.708 1.5 6.9625 1.5 9C1.5 11.0375 1.5085 11.292 1.545 12.092C1.5815 12.8905 1.7085 13.436 1.894 13.913C2.082 14.413 2.377 14.8665 2.759 15.2415C3.13358 15.6229 3.58675 15.9181 4.087 16.1065C4.564 16.2915 5.1095 16.4185 5.908 16.455C6.708 16.4915 6.963 16.5 9 16.5C11.037 16.5 11.292 16.4915 12.092 16.455C12.8905 16.4185 13.436 16.2915 13.913 16.106C14.413 15.918 14.8665 15.623 15.2415 15.241C15.6229 14.8664 15.9181 14.4133 16.1065 13.913C16.2915 13.436 16.4185 12.8905 16.455 12.092C16.4915 11.292 16.5 11.037 16.5 9C16.5 6.963 16.4915 6.708 16.455 5.908C16.4185 5.1095 16.2915 4.564 16.106 4.087C15.9177 3.58653 15.6225 3.13316 15.241 2.7585C14.8664 2.37711 14.4133 2.08193 13.913 1.8935C13.436 1.709 12.89 1.5815 12.0915 1.545C11.292 1.509 11.0375 1.5 9 1.5ZM9 2.8515C11.0025 2.8515 11.24 2.859 12.031 2.895C12.762 2.9285 13.159 3.05 13.4235 3.1535C13.7735 3.289 14.0235 3.452 14.286 3.714C14.5485 3.9765 14.711 4.2265 14.8465 4.5765C14.9495 4.841 15.0715 5.238 15.105 5.969C15.141 6.76 15.1485 6.9975 15.1485 9C15.1485 11.0025 15.141 11.24 15.105 12.031C15.0715 12.762 14.95 13.159 14.8465 13.4235C14.7265 13.7493 14.5349 14.044 14.286 14.286C14.044 14.535 13.7493 14.7265 13.4235 14.8465C13.159 14.9495 12.762 15.0715 12.031 15.105C11.24 15.141 11.003 15.1485 9 15.1485C6.997 15.1485 6.76 15.141 5.969 15.105C5.238 15.0715 4.841 14.95 4.5765 14.8465C4.25073 14.7265 3.956 14.5349 3.714 14.286C3.46509 14.044 3.27356 13.7493 3.1535 13.4235C3.0505 13.159 2.9285 12.762 2.895 12.031C2.859 11.24 2.8515 11.0025 2.8515 9C2.8515 6.9975 2.859 6.76 2.895 5.969C2.9285 5.238 3.05 4.841 3.1535 4.5765C3.289 4.2265 3.452 3.9765 3.714 3.714C3.95597 3.46502 4.2507 3.27349 4.5765 3.1535C4.841 3.0505 5.238 2.9285 5.969 2.895C6.76 2.859 6.9975 2.8515 9 2.8515Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.00001 11.5025C8.67138 11.5025 8.34596 11.4378 8.04235 11.312C7.73873 11.1862 7.46285 11.0019 7.23048 10.7695C6.9981 10.5372 6.81377 10.2613 6.688 9.95767C6.56224 9.65405 6.49751 9.32863 6.49751 9C6.49751 8.67137 6.56224 8.34595 6.688 8.04233C6.81377 7.73872 6.9981 7.46284 7.23048 7.23046C7.46285 6.99809 7.73873 6.81375 8.04235 6.68799C8.34596 6.56223 8.67138 6.4975 9.00001 6.4975C9.66372 6.4975 10.3002 6.76116 10.7695 7.23046C11.2389 7.69977 11.5025 8.3363 11.5025 9C11.5025 9.6637 11.2389 10.3002 10.7695 10.7695C10.3002 11.2388 9.66372 11.5025 9.00001 11.5025ZM9.00001 5.145C7.9776 5.145 6.99707 5.55115 6.27411 6.2741C5.55116 6.99706 5.14501 7.97759 5.14501 9C5.14501 10.0224 5.55116 11.0029 6.27411 11.7259C6.99707 12.4488 7.9776 12.855 9.00001 12.855C10.0224 12.855 11.003 12.4488 11.7259 11.7259C12.4489 11.0029 12.855 10.0224 12.855 9C12.855 7.97759 12.4489 6.99706 11.7259 6.2741C11.003 5.55115 10.0224 5.145 9.00001 5.145ZM13.9765 5.075C13.9765 5.31668 13.8805 5.54846 13.7096 5.71935C13.5387 5.89024 13.3069 5.98625 13.0653 5.98625C12.8236 5.98625 12.5918 5.89024 12.4209 5.71935C12.25 5.54846 12.154 5.31668 12.154 5.075C12.154 4.83332 12.25 4.60154 12.4209 4.43065C12.5918 4.25976 12.8236 4.16375 13.0653 4.16375C13.3069 4.16375 13.5387 4.25976 13.7096 4.43065C13.8805 4.60154 13.9765 4.83332 13.9765 5.075Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
        <p>All rights reserved to Adelina Vinarci, 2024®</p>
        <div className={styles.madeBy}>
          <p>Made by</p>{" "}
          <Link target="_blank" to={"https://origin3agency.com"}>
            Origin3 Agency
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
